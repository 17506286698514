import {LandingPage} from './LandingPage/LandingPage'
import './sass/main.scss';
import smoothscroll from 'smoothscroll-polyfill';

function App() {
  smoothscroll.polyfill();

  return (
    <LandingPage />
  );
}

export default App;
