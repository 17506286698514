import "./BuySection.scss";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useState } from "react";

export function BuySection() {

  const backend="https://prod-232.westeurope.logic.azure.com:443/workflows/917895015efc4dd2910e6e50c7a11d71/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=G6wqJAnJI3xwy27BIXWthhv9r_jkxnb7d3_zbpgeYx8"
  
  const { register, handleSubmit} = useForm();
  const [isSubmitted, setSubmitted] = useState(false);
  const onSubmit = data => {
    return axios.post(backend, data)
    .then(res => {
      console.log(res);
      console.log(res.data);
      setSubmitted(true);

    })
}
const onChange = () =>{
  setSubmitted(false);
}
  

  return (
    <section className="buy-section content-section" id="Buy">
      <h2>Sign up!</h2>
      <div className="content-section__main">
        <div className="account-creation-box">
    <form onChange={onChange} onSubmit={handleSubmit(onSubmit)}>
        <div className="form-element">
              <div className="label">Package</div>
              <select {...register("package")}> 
    <option value="std">Pay Per Use</option>
    <option value="ent">Enterprise</option>
    <option value="free">Free / OpenSource</option>
</select>            </div>
            <div className="form-element">
              <div className="label">Full Name</div>
              <input type="text" {...register("name")} />
            </div>
            <div className="form-element">
              <div className="label">Company Name</div>
              <input type="text"  {...register("company")}/>
            </div>
            <div className="form-element">
              <div className="label">E-Mail</div>
              <input type="text"  {...register("email")}/>
            </div>
            {isSubmitted && <div className="form-element">
             <p className="success">✅  Your request was submitted successfuly. We will get in touch with you!
             </p>
            </div>}
            <div className="form-element--btn">
              <button type="submit" disabled={isSubmitted}>Register</button>
            </div>
          </form>
       </div>
       </div>
       <div className="arrow-spacer"></div>

    </section>
    
  );
}
