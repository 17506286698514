import "./LandingPage.scss";
import "./WhatSection.scss";
import "./WhySection.scss";

import "./HowSection.scss";
import "./BuySection.scss";
import "./ScrollDownArrow.scss";
import {CalculationSection} from  "./CalculationSection";
import { useState, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import 'smoothscroll-anchor-polyfill'

import { HowSection } from "./HowSection";
import { WhySection } from "./WhySection";
import { PricingSection } from "./PricingSection";
import { BuySection } from "./BuySection";

window.__NAV_INDEX = 0;

export function LandingPage() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showCalc, setShowCalc] = useState(false);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const menuNavigation = (e, scrollTo, delay = 300) => {
    setMenuOpen(false);
    console.log("scrollTo", scrollTo);
    setTimeout(
      () =>
        document.querySelector(scrollTo).scrollIntoView({ behavior: "smooth" }),
      delay
    );
    // e.preventDefault();
  };

  return (
    <div className="toplevel-container">
      <div
        className={"container " + (isMenuOpen ? "active" : "inactive")}
        id="scroll-container"
      >
        <div
          className="float-toggle--bg"
        ></div>
        <div
          className="float-toggle"
          onClick={() => setMenuOpen(!isMenuOpen)}
        ></div>

        <header>
          <h2 className="logo">ARBO</h2>
        </header>
        <section className={"showcase"} id="Intro">
          <video autoPlay muted loop id="bgvid">
            <source src="xs-background-video.mp4" type="video/webm" />
          </video>
          <div className="overlay"></div>
          <div className="text">
            <h2>Plant a Tree with CI/CD</h2>
            <h3>Save the world with every build</h3>
            <p>
              ARBO is a zero-code solution to greenify your CI/CD-Pipeline.
              ARBO plugs seamlesly into your existing infrastructure and allows
              you to plant a tree with every deployment.
            </p>
            <a href="#Buy">
              Save the World
            </a>
          </div>
          <div className="arrow-conaainer">
            <a href="#How">
              <div className="arrow"></div>
            </a>
          </div>
        </section>
        <HowSection />
        <WhySection />
        <PricingSection />
        <CalculationSection />
       <BuySection />

        <div className="secondary-footer">
          <a href="#aa" onClick={(e)=>{setShowCalc(!showCalc); e.preventDefault();}}><sup>1</sup> A common CO2 calculation</a>
          {showCalc &&
          <pre>
                        |7 Developers:<br/>
                        |   1 Developer Machines: <br/>
                        |       Production CO2: 330kg/4y                  82.50kg<br/>
                        |       Transportation CO2: 30kg/4y                7.50kg<br/>
                        |       Usage:                                    30.00kg<br/>
                        |                                               ---------<br/>
                        |                                                120.00kg<br/>
                        |   1 Server:<br/>
                        |       Production CO2: 330kg/4y                  82.50kg<br/>
                        |       Transportation CO2: 30kg/4y                7.50kg<br/>
                        |       Usage:                                    90.00kg<br/>
                        |                                               ---------<br/>
                        |                                                180.00kg<br/>
                        |                                                        <br/>
                        |                                                        <br/>
                        |   10 Team-Memebrs: 120.00kg*10                1200.00kg<br/>
                        |   4  Servers:  180kg*4                         720.00kg<br/>
                        |                                               ---------<br/>
                        |                                                        <br/>
                        |                                                        <br/>
                        |                                             1920.00kg/y<br/>
                        |   128 Builds per year                        15kg/build<br/>

</pre>}



        </div>
        <footer>
          <div className="text">
            Copyright © 2021 GreenOps. All rights reserved.
          </div>
          <div className="links">
            <a href="mailto:info@green-ops.de">Contact&nbsp;Us</a>
            <a href="Imprint">Imprint</a>
            <a href="DataProtection">Data&nbsp;protection</a>
          </div>
        </footer>
      </div>
      <div className={"menu " + (isMenuOpen ? "active" : "inactive")}>
        <ul>
          <li>
            <a className="menu__link" href="#Intro">
              Home
            </a>
          </li>
     
          <li>
            <a className="menu__link" href="#How">
              How
            </a>
          </li>
          <li>
            <a className="menu__link" href="#Why">
              Why
            </a>
          </li>
          <li>
            <a className="menu__link" href="#Pricing">
              Pricing
            </a>
          </li>
          <li>
            <a className="menu__link" href="#Calc">
              Calculator
            </a>
          </li>
          <li className="menu__buy">
            <a className="menu__link" href="#Buy">
              Join now
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
