import "./PricingSection.scss";

export function PricingSection() {
  return (
    <section className="pricing-section content-section" id="Pricing">
      <h2>How much does it cost</h2>
      <div className="content-section__main">
        <div className="pricing">
        <div className="pricing-box">
          <h3>0€ </h3>
          <h5>per tree</h5>

          <h4>Free / OpenSource</h4>
          <div className="text">
          <p>Let us make your OpenSource deployment CO2 Neutral</p>
          <p>We offer a free contingent for all open source projects</p>
          <p>Let us make the world a better place. For Free as in Beer 🍺!</p>
          </div>
        </div>
        <div className="pricing-box">
          <h3>2€</h3>
          <h5>per tree</h5>

          <h4>Pay Per Use</h4>
          <div className="text">
          <p>Our most used plan.</p>
          <p>Pay only what you plant. No strings attached</p>
          <p>Available for everyone</p>

          </div>

        </div>
        <div className="pricing-box">
          <h3>1€ </h3>
          <h5>per tree</h5>

          <h4>Enterprise</h4>
          <div className="text">
          <p>Our enterpise ready high volume plan.</p>
          <p>Place your budget and be sure to only spend what you planned.</p>
          <p>Available with an upfront commitment of only 500 trees/months</p>
          </div>
        </div>
        </div>
       </div>
    <div className="arrow-spacer"></div>
      <div className="arrow-container">
        <a href="#Calc">
          <div className="arrow"></div>
        </a>
      </div>
    </section>
    
  );
}
