import "./WhySection.scss";

export function WhySection() {
  return (
    <section className="why-section content-section" id="Why">
      <h2>WHY ARBO IMPROVES THE WORLD</h2>
      <div className="content-section__main">
       <p className="why-intro-text">
        Digitalisation comes with a cost. Data centres alone consume 1% of the global energy. The production of a single laptop emits 330kg CO2. 
        The world climate crisis is imminent and we all need to change to a more sustainable way of creating value. Often it is not the giant leap, 
        but the many small steps that lead to a better, greener world.
        </p>
        <p className="why-intro-text">
        We created ARBO to help greenify the Software Development Process. Every Build from the idea to rollout emits ~15kg CO2<sup>1</sup>. While software is eating the world and 
        while we are striving to create faster and faster deployment cycles, we shall not neglect the environmental impact this creates. 
        </p>
        <div className="chart-container">
          <img className="flow-chart" alt="devops" src="devopscycle.png" />
        </div>

        <p className="why-intro-text">
            We are a strong believer that software is changing the world for a better place and DevOps is one of its most important drivers.
            As Developers we know that the will to work in a sustainable way is there, but time is a scarce resource. 
        </p>
        <p className="why-intro-text">
A tree offsets the CO2 balance by ~15kg effectively making your deployment CO2 neutral. </p>
<p className="why-intro-text">
So we created ARBO making it effortless for you to plant a tree with every deployment and making the world a better, greener place</p>
    </div>
    <div className="arrow-spacer"></div>
      <div className="arrow-container">
        <a href="#Pricing">
          <div className="arrow"></div>
        </a>
      </div>
    </section>
    
  );
}
