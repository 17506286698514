import "./HowSection.scss";

export function HowSection() {
  return (
    <section className="how-section content-section" id="How">
      <h2>How ARBO works</h2>
      <div className="content-section__main">
       <p className="how-intro-text">
          ARBO is delivered as plugin for the most common CI/CD solutions.
          Through our no-code configuration approach, the integration
          takes less than a day.
        </p>
        <div className="chart-container">
          <img className="flow-chart" alt="how arbo works" src="IDEA-2tra.png" />
        </div>
      
        <h3>ARBO currently supports:</h3>
        <div className="list-container">

        <ul>
          <li><img src="azure.png" alt="azure-logo" height="35px" />Azure DevOps</li>
          <li><img src="github.png" alt="github-logo"  height="35px" />Github Actions</li>
          <li><img src="gitlab.png" alt="gitlab-logo"  height="35px" />Gilab CI</li>
          <li><img src="teamcity.png" alt="teamcity-logo"  height="35px" />TeamCity</li>
          <li><img src="jenkins.png" alt="jenkins-logo"  height="35px" />Jenkins</li>
          <li><img src="concourse.png" alt="concourse-logo"  height="35px" />Concourse CI</li>
        </ul>
        <h3>comming soon...</h3>
        <ul>
          <li><img src="copado.png" alt="copado-logo"  height="35px" />Copado</li>
          <li><img src="aws.png" alt="aws-logo"  height="35px" />AWS CodePipeline</li>
          <li><img src="gcp.png" alt="gcp-logo"  height="35px" />GCP CloudBuild</li>
          <li><img src="tekton.png" alt="tekton-logo"  height="35px" />Tekton</li>
          <li><img src="spinnaker.png" alt="spinnaker-logo"  height="35px" />Spinnaker</li>
        </ul>
        </div>

        <p className="closing-text">
          If your solution is not listed, use our generic ARBO-API to integrate
          even into the most customized environments. <br />
          As easy as<br />
           <span className="code">$ <span className="code--cmd">curl</span> 
           <span className="code--arg"> -X POST </span><span className="code--prot">https://</span>
           <span className="code--domain">api.arbo.green-ops.de</span><span className="code--path">/trees/plant</span></span>
        </p>
      </div>
      <div className="arrow-spacer"></div>
      <div className="arrow-container">
        <a href="#Why">
          <div className="arrow"></div>
        </a>
      </div>
    </section>
  );
}
