import "./CalculationSection.scss";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React, {useState, useEffect} from 'react';

export function CalculationSection() {
  const [sliderValues, setSliderValues] = useState({laptops:12, hwRenewal:4, builds:3,team:12,servers:3});
  const [CO2perBuild, setCO2perBuild] = useState(0);

  const calcCO2 = (state) => {
    const co2ProductionLaptop = (state['laptops'] *360 ) / state['hwRenewal'] 
    const co2ProductionServer = (state['servers'] *360 ) / state['hwRenewal'] 
    const co2Usage = state['team'] * 30 + state['servers'] * 90 
    const co2 = (co2ProductionLaptop + co2ProductionServer + co2Usage) / (state['builds'] * 52)

    console.log('co2ProductionLaptop',co2ProductionLaptop)
    console.log('co2ProductionServer',co2ProductionServer)
    console.log('co2Usage',co2)
     console.log('total',(co2ProductionLaptop + co2ProductionServer + co2Usage) )

    console.log('totalPerBuild',co2)
    setCO2perBuild(co2.toFixed(2));
  }
  useEffect(()=>{
      calcCO2(sliderValues);
  },[sliderValues])
  //calcCO2();
  const sliderChanged = (slider, value) => {
   // console.log(slider,value);
    const state = {...sliderValues, [slider]:value}
    setSliderValues(state)
   // calcCO2(state)
    console.log(sliderValues)
  }
  return (
    <section className="calc-section content-section" id="Calc">
      <h2>CO<sub>2</sub> Calculator</h2>
      <div className="content-section__main">
        <p className="calc-intro-text ">Use our CO<sub>2</sub> Calculator to determine your very own CO<sub>2</sub> production and learn how many trees can help you offset it to become CO<sub>2</sub> neutral.</p>
      </div>
      <div className="box">
        <div className="">Laptops</div>
        <div className="calc-row">
          <Slider min={0} max={50} defaultValue={12}
          onChange={v => sliderChanged('laptops', v)} /> <input type="text" className="calc-input" value={sliderValues['laptops']}/> 
          
          
        </div>
        <div className="">Servers</div>
        <div className="calc-row">
            <Slider min={0} max={20} defaultValue={3}
          onChange={v => sliderChanged('servers', v)} /> <input type="text" className="calc-input" value={sliderValues['servers']}/> 
         
        </div>
        <div className="">Team Size</div>
        <div className="calc-row">
            <Slider min={0} max={50} defaultValue={12}
          onChange={v => sliderChanged('team', v)} /> <input type="text" className="calc-input" value={sliderValues['team']}/> 
         
        </div>
        <div className="">Builds per Week</div>
        <div className="calc-row">
            <Slider min={1} max={50} defaultValue={3}
          onChange={v => sliderChanged('builds', v)} /> <input type="text" className="calc-input" value={sliderValues['builds']}/> 
         
        </div>
        <div className="">Hardware renewal (years)</div>
        <div className="calc-row">
            <Slider min={1} max={10} defaultValue={4}
          onChange={v => sliderChanged('hwRenewal', v)} /> <input type="text" className="calc-input" value={sliderValues['hwRenewal']}/> 
         
        </div>
        <div className="co2result">CO<sub>2</sub> per build: <span className="co2result--result">{CO2perBuild}kg</span></div>
      </div>
      <div className="arrow-spacer"></div>
      <div className="arrow-container">
        <a href="#Buy">
          <div className="arrow"></div>
        </a>
      </div>
    </section>
  );
}
